import React from "react";
import Fade from "react-reveal/Fade";
import EmplTitle from "./EmplTitle";
import Employer from "./Employer";
import "./Team.css";

const teamData = [
  {
    name: "Iveta Bruvele",
    position: "Founder",
    link: "https://linkedin.com/in/iveta-bruvele-982a783a",
    pic: "iveta-bruvele"
  },
  {
    name: "Raivo Tambors",
    position: "CEO",
    link: "https://www.linkedin.com/in/raivotambors",
    pic: "raivo-tambors"
  },
  {
    name: "Margarita Jankova",
    position: "COO",
    link: "https://linkedin.com/in/margarita-jankova-3223a396",
    pic: "margarita-jankova"
  },
  {
    name: "Sergejs Zaharovs",
    position: "CIO",
    link: "https://linkedin.com/in/sergejs-zaharovs-176a025",
    pic: "sergey-zharovs"
  },
  {
    name: "Eduards Zeps",
    position: "Country Manager of Spain",
    link: "https://www.linkedin.com/in/eduards-zeps-94335121/",
    pic: "eduards-zeps"
  },
  {
    name: "Diāna Koņina",
    position: "Country Manager of Poland",
    link: "https://linkedin.com/in/diana-konina-58649b129",
    pic: "diana-konina"
  },
  {
    name: "Kirils Golubevs",
    position: "Frontend Lead",
    link: "https://www.linkedin.com/in/kirils-golubevs-448779105/",
    pic: "kirils-golubevs"
  },
  {
    name: "Aleksejs Goldins",
    position: "Head of IT Infrastructure",
    link: "https://www.linkedin.com/in/alexey-goldin-b4323ab0/",
    pic: "aleks-goldins"
  },
  {
    name: "Deniss Viktorovs",
    position: "Technical Lead",
    link: "https://www.linkedin.com/in/denis-viktorov-b1444b57/",
    pic: "deniss-victorovs"
  }
];

const Team = () => (
  <div className="team">
    <div className="container">
      <div className="empl-table">
        <Fade>
          <EmplTitle
            title="FinTech Builders"
            annotation="Our leadership team brings together expertise across product, design, engineering, sales, and marketing."
          />
          {teamData.map(item => (
            <Employer key={item.pic} {...item} />
          ))}
        </Fade>
      </div>
    </div>
  </div>
);

export default Team;
