import React from "react";
import Heading from "./Heading";
import { Button } from "./ui";
import TextBlock from "./TextBlock";
import Vacancies from "./Vacancies";
import Carousel from "./Carousel";
import "./Career.css";

import IcnHours from "../images/icn_hours.svg";
import IcnDirect from "../images/icn_direct.svg";
import IcnOffice from "../images/icn_office.svg";
import IcnStructure from "../images/icn_structure.svg";
import officeInt05 from "../images/office-int-05.jpg";
import officeInt02 from "../images/office-int-02.jpg";
import officeInt08 from "../images/office-int-08.jpg";

const textBlocks = [
  {
    delay: 0,
    icnSrc: IcnHours,
    title: "Flexible hours to fit your life",
    annotation:
      "No need to punch the clock when you arrive and leave. As long as you get the job done, you can be flexible with your hours.",
  },
  {
    delay: 100,
    icnSrc: IcnOffice,
    title: "An office you’ll love to be in",
    annotation:
      "We treat our people well. This includes an office with all the modern perks to relax along with the latest tech to do your job.",
  },
  {
    delay: 200,
    icnSrc: IcnDirect,
    title: "A direct and no- nonsense style",
    annotation:
      "Expect to be talked to directly, honestly and without sugar coating feedback between pats on the back.",
  },
  {
    delay: 300,
    icnSrc: IcnStructure,
    title: "Enjoy a flat org structure",
    annotation:
      "Our teams are organized around getting things done quickly and effectively. Org structures only get in the way.",
  },
];

const carouselImgs = [officeInt05, officeInt08, officeInt02];

const Career = () => (
  <div className="container">
    <div className="career">
      <Heading
        title="Work at Wandoo"
        annotation="We’re always looking for great people that have the skill and enthusiasm to scale our business to the next level."
        className="heading"
      />
      <Button
        btntext="View job openings"
        link="https://wandoo.recruiterbox.com/"
      />
      <Carousel className="mar-t6b6">
        {carouselImgs.map((item) => (
          <img src={item} key={item} alt="Office" />
        ))}
      </Carousel>
    </div>
    <div className="icn-cols">
      {textBlocks.map((block) => (
        <TextBlock
          className="text-block center mar-t3b3"
          key={block.title}
          {...block}
        />
      ))}
    </div>
    <Vacancies />
  </div>
);

export default Career;
