import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { ContTitle } from './ui'
import Social from './nav/Social';
import './Contacts.css';
import OfficePict from '../images/office.jpg';

export default class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: OfficePict,
      class: 'office-pict'
    }
  }

  enterHandler = (img) => (event) => {
    this.setState({
      image: img,
      class: 'office-pict act',
    });
  }

  leaveHandler = () => {
    this.setState({
      image: this.state.image,
      class: 'office-pict'
    });
  }

  render() {

    return (
      <div className="contacts">
        <div className="container">
          <Fade>
            <div className="cols contacts-box">
              <div className="addressAndSocialLinks">
              <ContTitle
                  title="Contacts"
                  address="Kārļa Ulmaņa gatve 119,"
                  office={`Office Complex "Baltais Vējš"`}
                  country="Mārupe, Mārupes novads, Latvija"
                  tel="+371 66 155 565"
                  email="info@wandoofinance.com"
                  link="https://goo.gl/maps/RPQr9NQ8boM2"
                  textlink="See on the map"
                />
                <Social />
              </div>


                <div className="back-pic">
                  <img
                    src={this.state.image}
                    className={this.state.class}
                    alt="Wandoo Office"
                  />
                </div>
            </div>
          </Fade>
        </div>
      </div>
    );
  }
}