import React from 'react';
import { ArrowLink } from './ui';
import './Team.css';

const Employer = props => (
  <div className="employer left">
    <div className="empl-info">
      <p className="bigger bold">{props.name}</p>
      <p>{props.position}</p>
      <ArrowLink link={props.link} textlink="Read more" />
    </div>
    <div className={props.pic} alt={props.name}></div>
  </div>
);

export default Employer;