import React from "react";
import Fade from "react-reveal/Fade";
import VideoMp4 from "../video/office-wandoo.mp4";
import VideoWebm from "../video/officeday-wandoo.webm";
import VideoOgv from "../video/office-wandoo.ogv";
import "./Main.css";

const Video = () => (
  <Fade>
    <div className="video" alt="Video">
      <video
        className="videofile"
        preload="yes"
        id="vid"
        playsInline
        autoPlay
        controls={false}
        loop
        muted
      >
        <source src={VideoMp4} type="video/mp4" />
        <source src={VideoWebm} type="video/webm" />
        <source src={VideoOgv} type="video/ogv" />
      </video>
    </div>
  </Fade>
);

export default Video;
