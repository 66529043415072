import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import Fade from 'react-reveal/Fade';
import '../Main.css'

const Title = () => {

  const desktopTitle = (
    <h1 className="title-desktop">
      Creating easier ways to borrow.<br />
      <ReactTypingEffect
        text={['Better ways to invest.', 'The best fintech products.']}
      />
    </h1>
  );

  const mobileTitle = (
    <h1 className="title-mobile">
      <ReactTypingEffect
        staticText="Creating easier ways to borrow."
        text={['Better ways to invest.', 'The best fintech products.']}
      />
    </h1>
  )

  return (
    <Fade delay={200} distance="20%" top>
      {desktopTitle}
      {mobileTitle}
    </Fade>
  );
}

export default Title;