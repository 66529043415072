import React from 'react';
import { Title } from './ui';
import '../App.css';

const Main = () => (
  <div className="container">
    <div className="main">
      <Title />
    </div>
  </div>
);

export default Main;