import React from 'react';
import Fade from 'react-reveal/Fade';
import './About.css';
import Heading from './Heading';
import company from '../images/office-logo.jpg';
import TextBlock from './TextBlock';
import Fullstack from '../images/icn_fullstack.svg';
import Finstrategy from '../images/icn_finstrategy.svg';

const About = () => {
  const textBlocks = [
    {
      delay: 0,
      icnSrc: Fullstack,
      title: 'Full Stack Development',
      annotation: 'Mobile, web and online applications, Front and Back-End development, Quality Assurance, Design.'
    },
    {
      delay: 100,
      icnSrc: Finstrategy,
      title: 'Financial Innovation Management',
      annotation: 'Strategic partnership, Product development, Business consulting.'
    }
  ];
  return (
    <div className="about">
      <div className="container">
        <Heading
          title="Financial product and software development"
          annotation="We build IT ecosystems for the financial industry that harness the opportunities offered by global best practices and technologies. And we combine our knowledge and expertise of IT and the financial sector to launch our own innovative products." />
        <Fade>
          <img
            src={company} className="img-mid mar-t3"
            alt="Office Wandoo"
          />
        </Fade>
        <div className="cols">
          {textBlocks.map(block => (
            <TextBlock
              {...block}
              key={block.title}
              className="text-block left mar-t6b6"
            />
          ))}
        </div>
      </div>
    </div>
  );
}


export default About;