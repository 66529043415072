import React from "react";
import Fade from "react-reveal/Fade";
import { DigitBlock } from "./ui";
import Map from "./Map";
import "./About.css";

const digitalBlocks = [
  {
    digit: 3,
    discpription: "Markets",
    delay: 0,
  },
  {
    digit: 850000,
    discpription: "Customers",
    delay: 200,
  },
  {
    digit: 87,
    discpription: "Employees",
    delay: 400,
  },
];

const Footprint = () => (
  <div className="container">
    <div className="footprint">
      <Fade>
        <h2 className="mar-t6b6 center">Footprint</h2>
      </Fade>
      <Fade>
        <Map />
      </Fade>
    </div>
    <div className="digits">
      {digitalBlocks.map((block) => (
        <DigitBlock {...block} key={block.discpription} />
      ))}
    </div>
  </div>
);

export default Footprint;
