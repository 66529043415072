import React from 'react';
import { Header, Main, Video, About, Footprint, Team, Career, Contacts, Footer } from './components';
import './App.css';

const Content = () => (
  <section id="content">
      <Header />
      <section id="main">
        <Video />
        <Main />
      </section>
      <section id="about">
        <About />
        <Footprint />
      </section>
      <section id="team">
        <Team />
      </section>
      <section id="career">
        <Career />
      </section>
      <section id="contacts">
        <Contacts />
      </section>
      <Footer />
  </section>
);

export default Content;