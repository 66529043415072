import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import '../App.css';
import './Header.css';
import logo from '../images/logo-wandoo.svg';

const Header = () => (
  <div className="header">
    <div className="header-bg">
      <AnchorLink href="#main">
        <img src={logo} className="logotype" alt="logo" />
      </AnchorLink>
    </div>
  </div>
);

export default Header;