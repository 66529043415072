import React from 'react';
import icnLocation from '../images/icn_map-pin.svg';
import './Career.css';

const Role = props => (
  <div className="role left">
    <h3 className="title-two-line"><a href={props.link}>{props.position}</a></h3>
    <p className="mar-t1b1">{props.dep}</p>
    <div className="location">
      <img src={icnLocation} className="icn-location" alt="Location" />
      <p>{props.location}</p>
    </div>
  </div>
);

export default Role;