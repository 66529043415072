import React, { Component } from "react";
import "./App.css";
import Nav from "./components/nav";
import Content from "./Content";
export default class App extends Component {
  state = { loading: true };

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 100);
  }

  componentDidUpdate(_, prevState) {
    if (this.state.loading !== prevState.loading && !this.state.loading) {
      const htmlSpinner = document.querySelector("#loader");

      htmlSpinner?.parentNode.removeChild(htmlSpinner);
    }
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return null;
    }

    return (
      <div className="general">
        <Nav />
        <Content />
      </div>
    );
  }
}
