import React from 'react';
import Icon from '../Icon'

const IcnMenu = () => (
  <div className="menu-social">
    <a href="https://www.facebook.com/wandoofinancegroup/">
      <Icon name="facebook" />
    </a>
    <a href="https://www.linkedin.com/company/wandoo-finance/">
      <Icon name="linkedin" />
    </a>
    <a href="https://www.instagram.com/wandoofinancegroup/">
      <Icon name="instagram" />
    </a>
  </div>
);

export default IcnMenu;