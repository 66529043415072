import React, { Component } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import Roles from './Roles';
import Role from './Role';

export default class Vacancies extends Component {
  state = {
    error: null,
    isLoaded: false,
    items: []
  }

  componentDidMount() {
    fetch('https://jsapi.recruiterbox.com/v1/openings?client_name=wandoo')
      .then(response => response.json())
      .then(result => {
        this.setState({
          isLoaded: true,
          items: result.objects
        });
      },
        error => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  normilizeStr(str) {
    if (!str) {
      return ''
    }
    str = str.trim()
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  }

  render() {

    const { error, isLoaded, items } = this.state;
    if (!items.length) {
      return null;
    }
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {

      return (
        <div className="vacancies">
          <h2 className="mar-b6 center">Vacancies</h2>
          <Roles addClass="vacancies-slide">
            {items.map(item => (
              <Role
                key={item.id}
                position={item.title}
                link={item.hosted_url}
                location={`${this.normilizeStr(item.location.city)}, ${this.normilizeStr(item.location.country)}`}
              />
            ))}
          </Roles>
          <ScrollContainer className="roles-row">
            {items.map(item => <Role
              key={item.id}
              position={this.normilizeStr(item.title)}
              link={item.hosted_url}
              location={`${this.normilizeStr(item.location.city)}, ${this.normilizeStr(item.location.country)}`}
            />)}
          </ScrollContainer>
        </div>
      );
    }
  }
}
