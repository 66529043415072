import React, { Component } from "react";
import IcnMenu from "./IcnMenu";
import Content from "./Content";
import Social from "./Social";
import "../../App.css";
import "./Nav.css";

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleClickLink = this.handleClickLink.bind(this);
  }

  handleClick() {
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));
  }

  handleClickLink() {
    this.setState({ isToggleOn: true });
  }

  render() {
    return (
      <div>
        <div
          className={
            this.state.isToggleOn ? "mobile-nav back-out" : "mobile-nav back-in"
          }
        >
          <div onClick={this.handleClick}>
            <IcnMenu />
          </div>
          <div
            className={
              this.state.isToggleOn
                ? "mobile-nav-content slide-out"
                : "mobile-nav-content slide-in"
            }
          >
            <Content clicked={this.handleClickLink} />
            <Social />
          </div>
        </div>
        <Content />
      </div>
    );
  }
}
