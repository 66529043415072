import React from 'react';
import Fade from 'react-reveal/Fade';

const Heading = props => (
  <Fade>
    <div className="heading">
      <h2>{props.title}</h2>
      <p className="mar-t3">{props.annotation}</p>
    </div>
  </Fade>
);

export default Heading;