import React from 'react';
import './Team.css'

const EmplTitle = props => (
  <div className="team-heading left">
    <h2>{props.title}</h2>
    <p className="mar-t2 mar-b3">{props.annotation}</p>
  </div>
);

export default EmplTitle;