import React from 'react';
import Fade from 'react-reveal/Fade';

const TextBlock = props => (
  <div className={props.className}>
    <Fade delay={props.delay}>
      <img className="icn60 mar-b2" src={props.icnSrc} alt={props.title} />
      <h3>{props.title}</h3>
      <p className="mar-t1b1">{props.annotation}</p>
    </Fade>
  </div>
);

export default TextBlock;