import React from 'react';
import TrackVisibility from 'react-on-screen';
import CountUp from 'react-countup';
import Fade from 'react-reveal/Fade';

const DigitBlock = props => (
  <Fade delay={props.delay}>
    <div className="block-border" >
      <h2>
        <TrackVisibility>
          {({ isVisible }) => isVisible&& <CountUp start={0} end={props.digit} duration={2} separator=" " />}
        </TrackVisibility>
      </h2>
      <p className="bold text-green mar-b1">{props.discpription}</p>
    </div>
  </Fade>
);

export default DigitBlock;