import React from "react";
import Scrollspy from "react-scrollspy";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "../../App.css";
import "./Nav.css";

const links = [
  {
    href: "#main",
    text: "Main",
  },
  {
    href: "#about",
    text: "About",
  },
  {
    href: "#team",
    text: "Team",
  },
  {
    href: "#career",
    text: "Career",
  },
  {
    href: "#contacts",
    text: "Contacts",
  },
];

const Contents = ({ clicked }) => (
  <Scrollspy
    items={["main", "about", "team", "career", "contacts"]}
    className="menu-content desktopNav"
    currentClassName="nav-act activeDesktopNav"
    offset={-100}
  >
    {links.map((item) => (
      <li key={item.text} onClick={clicked}>
        <AnchorLink href={item.href}>{item.text}</AnchorLink>
      </li>
    ))}
  </Scrollspy>
);

export default Contents;
