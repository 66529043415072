import React, { Component } from 'react';
import classNames from 'classnames';
import Fade from 'react-reveal/Fade';
import chunk from 'lodash/chunk';

export default class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: 0,
      width: props.width
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  handleClick = (id) => {
    this.setState({
      activeId: id
    });
  }

  nextSlide = () => {
    const { activeId } = this.state
    this.setState({
      activeId: activeId + 1 === this.props.children.length ? 0 : activeId + 1
    });
  }

  render() {

    const { activeId, width } = this.state
    const cards = this.props.children
    const content = cards.map((card, id) => <div key={id}>{card}</div>);

    let cardNum;

    if (width <= 840 && width >= 540) {
      cardNum = 2;
    } else if (width <= 540) {
      cardNum = 1;
    } else {
      cardNum = 3;
    }

    const slides = chunk(content, cardNum).map((slide, id) =>
      <div
        key={id}
        className={classNames('slide', activeId === id ? 'acts' : '')}
        onTouchMove={() => this.handleClick(id)}
      >
        {slide}
      </div>
    );

    let indicators
    if (slides.length > 1) {
      indicators = chunk(content, cardNum).map((slide, id) =>
        <div
          key={id}
          onClick={() => this.handleClick(id)}
          className="indicators"
        >
          <div className={activeId === id ? 'active' : ''} />
        </div>
      );
    } else {
      indicators = false;
    }

    const sliderStyles = classNames('slider', this.props.addClass);

    return (
      <div className={sliderStyles}>
        {slides}
        <Fade>
          <div className="indicators-set">
            {indicators}
          </div>
        </Fade>
      </div>
    );
  }
}