import React, { Component } from 'react';
import IcnArrow from '../../images/icn_arrow.svg';
import IcnArrowHover from '../../images/icn_arrow_hover.svg';

export default class ArrowLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrow: IcnArrow,
    }
  }

  enterHandler = () => {
    this.setState({
      arrow: IcnArrowHover,
    });
  }

  leaveHandler = () => {
    this.setState({
      arrow: IcnArrow,
    });
  }

  render() {
    return (
      <div onMouseEnter={this.enterHandler} onMouseLeave={this.leaveHandler} className="arrow-link">
        <a href={this.props.link}>{this.props.textlink}</a>
        <img src={this.state.arrow} alt="more" className="arrow" />
      </div>
    );
  }
}