import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

export default class Carousel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeId: 0
    }
  }

  handleClick = (id) => {
    this.setState({
      activeId: id
    });
  }

  nextSlide = () => {
    const { activeId } = this.state
    this.setState({
      activeId: activeId + 1 === this.props.children.length ? 0 : activeId + 1
    });

  }

  render() {

    const { activeId } = this.state
    const slides = this.props.children

    const content = slides.map((slide, id) =>
      <img
        src={slide.props.src}
        alt={slide.props.alt}
        key={id}
        className={activeId === id ? 'act' : ''}
        onClick={this.nextSlide}
      />
    );

    const indicators = slides.map((slide, id) =>
      <div
        key={id}
        onClick={() => this.handleClick(id)}
        onTouchMove={() => this.handleClick(id)}
        className="indicators">

        <div className={activeId === id ? 'active' : ''} />
      </div>
    );

    return (

      <Fade>
        <div className={`carousel-box ${this.props.className}`}>

          <div className="carousel">
            {content}
          </div>

          <div className="indicators-set">
            {indicators}
          </div>

        </div>
      </Fade>

    );
  }
}