import React, { Component } from 'react';

export default class IcnMenu extends Component {

  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
  }

  handleClick = () => () => {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    return (
      <div className="menu">
        <div onClick={this.handleClick} className={this.state.isToggleOn ? 'icn-menu' : 'icn-menu-active'}>
          <div />
          <div />
        </div>
      </div>
    );
  }
}