import React from "react";
import Fade from "react-reveal/Fade";
import ArrowLink from "./ArrowLink";

const ContTitle = (props) => {
  const mailLink = "mailto:" + props.email;

  return (
    <Fade>
      <div className="conttitle left">
        <div className="continfo mar-b2">
          <h2>{props.title}</h2>
          <p className="mar-t2">{props.address}</p>
          <p>{props.office}</p>
          <p>{props.country}</p>
          <p>{props.tel}</p>
          <a href={mailLink}>{props.email}</a>
        </div>
        <ArrowLink link={props.link} textlink={props.textlink} />
      </div>
    </Fade>
  );
};

export default ContTitle;
