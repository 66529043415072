import React from 'react';
import Fade from 'react-reveal/Fade';
import './Footer.css'

const year = new Date().getFullYear();

const Footer = () => (
  <Fade>
    <div className="footer">
      <div className="container">
        <div className="footinfo">
          <p>All rights reserved © {year}</p>
          <p>Wandoo Finance Group</p>
        </div>
      </div>
    </div>
  </Fade>
);

export default Footer;