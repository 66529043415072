import React from 'react';
import './icon.css';

const renderIcon = name => {
  const icons = {
    facebook: <path d="m6.5 18h2.9v-8.5h2.2l.4-2.7h-2.6v-1.9c0-.6.4-1.3.9-1.3h1.5v-2.6h-1.8c-2.9.1-3.5 1.9-3.5 3.7v2.1h-1.5v2.7h1.5z" />,
    twitter: <path d="m5.7 16c6.8 0 10.5-5.4 10.5-10.1 0-.2 0-.3 0-.5.7-.4 1.3-1 1.8-1.7-.7.3-1.4.5-2.1.6.8-.4 1.3-1.1 1.6-2-.7.4-1.5.7-2.3.8-1.4-1.4-3.8-1.5-5.3-.1-.9.8-1.3 2.1-1 3.3-3-.1-5.7-1.5-7.6-3.7-1 1.6-.5 3.7 1.1 4.7-.6 0-1.2-.2-1.7-.4 0 1.7 1.2 3.1 3 3.5-.5.1-1.1.2-1.7.1.5 1.4 1.9 2.4 3.5 2.5-1.3 1-2.9 1.5-4.6 1.5-.3 0-.6 0-.9-.1 1.7 1 3.7 1.6 5.7 1.6" />,
    linkedin: <path d="m1.3 17h3.3v-10.7h-3.3zm1.6-16c-1 0-1.9.9-1.9 1.9 0 1.1.9 2 1.9 2 1.1 0 1.9-.9 1.9-2 0-1-.8-1.9-1.9-1.9zm10.1 5c-1.6 0-2.7.9-3.1 1.7v-1.4h-3.2v10.7h3.3v-5.3c0-1.4.3-2.7 2-2.7s1.7 1.6 1.7 2.8v5.2h3.3v-5.9c0-2.8-.6-5.1-4-5.1z" />,
    instagram: <path d="m13.5 6c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm-4.5 5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm-4-11c-1.7 0-3 1.3-3 3v8c0 1.7 1.3 3 3 3h8c1.7 0 3-1.3 3-3v-8c0-1.7-1.3-3-3-3zm0-2h8c2.8 0 5 2.2 5 5v8c0 2.8-2.2 5-5 5h-8c-2.8 0-5-2.2-5-5v-8c0-2.8 2.2-5 5-5z" />,
    defult: null
  };
  return icons[name || 'default'];
};

const Icon = ({
  name, clicked, color, addClass
}) => (
  <div
    className={['icn18px', addClass].join(' ')}
    onClick={clicked}
  >
    <svg
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      fill={color || '#000000'}
    >
      {renderIcon(name)}
    </svg>
  </div>
);

export default Icon;